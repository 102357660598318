var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-page reverse"},[_c('div',{staticClass:"detail-page__left"},[_c('CardLeftBlock',{staticClass:"clear-992",attrs:{"backgroundImage":'product-admin'}},[_c('template',{slot:"body"},[_c('div',{staticClass:"card-detail-left__date"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"DD MMM, YYYY"))+" ")])])],2)],1),_c('div',{staticClass:"detail-page__right"},[_c('CardRightBlock',[_c('template',{slot:"header"},[_c('div',{staticClass:"order-create__head"},[_c('div',{staticClass:"order-create__head-line"},[_c('div',{staticClass:"order-create__header-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'consolidationUnion_backTo',
                ])}}}),_c('router-link',{staticClass:"detail-page__head-back",attrs:{"to":_vm.$store.getters.GET_PATHS.reportsConsolidation}},[_c('LinkBack',{attrs:{"value":_vm.$t('consolidationUnion_backTo.localization_value.value')}})],1)],1)]),_c('div',{staticClass:"order-create__head-title mb-2",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'consolidationUnion_editConsUnionLabel',
                ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidationUnion_editConsUnionLabel.localization_value.value'))+" ")])])]),_c('template',{slot:"body"},[_c('div',{staticClass:"order-create__section"},[_c('LabelSendDetails',{attrs:{"CUL":_vm.CUL,"warehousesData":_vm.warehousesData}}),_c('LabelDocumentsBlock',{attrs:{"CUL":_vm.CUL}})],1)]),_c('template',{slot:"footer"},[_c('div',{staticClass:"order-create__footer"},[_c('div',{staticClass:"order-create__footer-link",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_cancel',
                ])}}}),_c('router-link',{staticClass:"site-link site-link--alt",attrs:{"to":_vm.$store.getters.GET_PATHS.reportsConsolidation}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")])],1),_c('div',{staticClass:"order-create__footer-btn ml-auto",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_create',
                ])}}}),_c('MainButton',{staticClass:"order-create__footer-btn-i",attrs:{"value":_vm.$t('common_save.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('saveConsolidationUnionLabel', true)}}})],1)])])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }