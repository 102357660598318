<template>
  <ConsolidationUnionEditLabelAdmin
      :CUL="CUL"
      :warehousesData="warehousesData"
      @saveConsolidationUnionLabel="saveConsolidationUnionLabel"
  />
</template>

<script>
  import ConsolidationUnionEditLabelAdmin from "./ConsolidationUnionEditLabelAdmin/ConsolidationUnionEditLabelAdmin";
  import {ConsolidationUnionLabel} from "../../models/ConsolidationUnionLabel";
  import {consolidationUnionLabelMixin} from "../../../../../mixins/consolidationUnionMixins/consolidationUnionLabelMixin";
  // import {
  //   CONSOLIDATION_UNION_WAREHOUSES_ARRAY
  // } from "../../../../../staticData/staticVariables";

  export default {
    name: "ConsolidationUnionEditLabel",

    components: {
      ConsolidationUnionEditLabelAdmin,
    },

    mixins: [
      consolidationUnionLabelMixin
    ],

    data() {
      return {
        CUL: new ConsolidationUnionLabel(),
        warehousesData: [],
      }
    },

    mounted() {
      this.initConsolidationUnionLabelEdit()
    },

    methods: {
      async downloadFileSingle(file) {
        let returnFile = null
          if (file === 'small_image') {
            await this.getFileFromServer(file, 'image').then(document => {
              returnFile = document
            })
          }

          if (file?.extension === 'pdf') {
            await this.getFileFromServer(file, 'pdf').then(document => {
              returnFile = document
            })
          }

          return returnFile
      },

      initConsolidationUnionLabelEdit() {

        this.$store.dispatch('getConsolidationUnionLabelEdit', this.$route.params.id).then(response => {
          let respData = this.getRespData(response)
          this.warehousesData = respData.warehouses
          // this.warehousesData = respData.warehouses.filter(item => {
          //   return CONSOLIDATION_UNION_WAREHOUSES_ARRAY.find(findItem => {
          //     return findItem.id === item.id
          //   })
          // })

          this.CUL.setConsolidationUnionLabelItem(respData['consolidation-union-label'], this.warehousesData, this)

          if (respData['consolidation-union-label'].proform_document_file !== null) {
            this.downloadFileSingle(respData['consolidation-union-label'].proform_document_file).then((file) => {
              this.CUL.data.invoice.setDownloadFiles([file])
              console.log(111, this.CUL.data.invoice, file);
            })
          }
          if (respData['consolidation-union-label'].fedex_tsca_document_file !== null) {
            this.downloadFileSingle(respData['consolidation-union-label'].fedex_tsca_document_file).then((file) => {
              this.CUL.data.toxicSubstanceCert.setDownloadFiles([file])
              console.log(222, this.CUL.data.toxicSubstanceCert, file);
            })
          }
          if (respData['consolidation-union-label'].fedex_dbts_document_file !== null) {
            this.downloadFileSingle(respData['consolidation-union-label'].fedex_dbts_document_file).then((file) => {
              this.CUL.data.dropBallCert.setDownloadFiles([file])
              console.log(333, this.CUL.data.dropBallCert, file);
            })
          }
          if (respData['consolidation-union-label'].fedex_coof_document_file !== null) {
            this.downloadFileSingle(respData['consolidation-union-label'].fedex_coof_document_file).then((file) => {
              this.CUL.data.certificateOfOrigin.setDownloadFiles([file])
              console.log(444, this.CUL.data.dropBallCert, file);
            })
          }

        }).catch(error => this.createErrorLog(error))
      },

    }
  }
</script>

<style scoped>

</style>